#list-with-us {

    .pricing-input {
        border: 1px solid #000;
        padding: 4px 15px !important;
        display: flex;
        align-items: center;
        margin-top: 0rem;
        border: 1px solid #ccc;
        border-radius: 6px;
        height: 40px;
        font-size: 16px;
        width: 100%;
        background-color: #fafafa;
    }

    .buildings-form {
        margin: 60px;
        width: 100%;
    }

    .complete-listing {
        font-size: 20px;
        line-height: 25px;
        font-weight: 200;
        color: #333333;
        text-align: center;
        margin-bottom: 30px;
    }

    .contact-us {
        font-size: 20px;
        line-height: 25px;
        font-weight: 200;
        color: #333333;
        text-align: center;
        margin-bottom: 30px;
    }

    @media (max-width: 300px) {
        .contact-us {
            margin-bottom: 30px;
        }
    }

    .rent {
        color: rgba(112, 214, 33, 1);
    }

    .sc-dnqmqq.fVyNgK {
        font-weight: 600;
        font-size: 16px;

    }

    .sc-dnqmqq.jhpinq {
        font-weight: 600;
        font-size: 16px;

    }

    .sc-dnqmqq.bOpoZL {
        font-weight: 600;
        font-size: 16px;
    }

    .buildings-form-title p,
    .buildings-picture-title p,
    .upload__image-wrapper p {
        font-size: 20px;
        line-height: 1;
        color: #c2c2c2;
        margin-bottom: 30px;
        text-align: center;
    }

    .buildings {
        display: flex;
        margin: 0 auto;
        margin: 28px 100px 30px 100px;
        box-shadow: 0 0 3px #515151;
        transition: all 0.3s cubic-bezier(.25, .8, .25, 1);
    }

    .title {
        display: flex;
        justify-content: center;
        align-items: center;
        color: rgba(112, 214, 33, 1);
        font-size: 50px;
        margin-top: 20px;
        font-weight: 600;

    }

    .imgDiv {
        margin-top: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .imgLogo {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 318.541px;
        transition: width 0.3s ease 0s;
        height: 100.735px;
        object-fit: contain;
    }


    @media (max-width: 300px) {
        .imgLogo {
            width: 243.541px;
        }
    }

    @media (max-width: 900px) {
        .buildings {
            margin: 0px !important;
            border: none;
            box-shadow: none;
        }
    }

    @media (max-width: 400px) {
        .title {
            font-size: 40px;

        }
    }

    @media (max-width: 400px) {
        .contact-us {
            font-size: 16.2px;

        }
    }

    @media (max-width: 400px) {
        .buildings-form {
            margin: 20px;
            width: 100%;
        }
    }
}