#text-editor {
  .editorClassName {
    border: 1px solid #d3d3d3;
    min-height: 100px;
    border-radius: 4px;
    margin-top: 30px;
    padding: 0 10px;
  }
}
#quill-editor-container {
  //used to set the size value of text in texteditor
  .ql-snow .ql-picker.ql-size .ql-picker-item[data-value]::before {
    content: attr(data-value);
    font-size: attr(data-value) px !important;
  }
  //used to set the height for all dropdown buttons
  .ql-toolbar.ql-snow .ql-picker.ql-expanded .ql-picker-options {
    max-height: 230px;
    overflow: auto;
  }
  .ql-editor {
    font-size: 16px;
  }
  //used to set the size value for dropdown label
  .ql-snow .ql-picker.ql-size .ql-picker-label[data-value]::before {
    content: attr(data-value);
  }
}
