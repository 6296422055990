#date-picker {
  border: 0px;
  background: transparent;
  width: 100%;
  padding: 0px;
  padding-left: 40px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

input[id="date-picker"]:focus {
  outline: none !important;
}

.left-10 {
  left: 10px;
}

.css-1jllj6i-control {
  display: none !important;
}

.css-1r4vtzz,
.css-48ayfv {
  width: 100% !important;
  border: none !important;
  background-color: #fafafa !important;
  border: 1px solid #ccc !important;
  box-shadow: none !important;
  border-radius: 6px !important;
  padding: 7px 7px 7px 14px !important;
}

.css-1r4vtzz,
.css-48ayfv {
  border: 1px solid #ccc !important;
  box-shadow: none !important;
  width: 100% !important;
  height: 40px;
  border-radius: 6px !important;
  background-color: #fafafa !important;
  font-size: 20px !important;
  padding: 0 6px;
}

.css-1v99tuv {
  letter-spacing: 0.1px !important;
  opacity: 0.6 !important;
  font-size: 16px !important;
  font-weight: 400 !important;
  color: #3c4240 !important;
  padding: 0 3px;
}

.css-1v99tuv:focus {
  background-color: red !important;
}

.label-multi {
  font-family: "Source Sans Pro", sans-serif;
  font-size: 15px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: 0.3px;
  color: rgb(61, 60, 60);
}

.label-multi-lu {
  font-family: "Source Sans Pro", sans-serif;
  font-size: 15px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: 0.3px;
  color: rgb(61, 60, 60);
  margin-bottom: 20px;
}

@media only screen and (max-width: 900px) {
  .label-multi-lu {
    margin-bottom: 11px;
  }
}

.react-checkbox * {
  font-size: 18px !important;
}