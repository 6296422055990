.ant-modal-confirm .ant-modal-confirm-btns {
  float: initial !important;
  display: flex !important;
  justify-content: space-between;
  align-items: center;

  .ant-btn {
    padding: 19px 20px !important;
    font-weight: 600;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .ant-btn-dangerous {
    color: var(--third-color) !important;
    border-color: var(--third-color) !important;
  }
}
.ant-modal-confirm-body .ant-modal-confirm-title {
  font-size: 20px !important;
  margin-top: 1rem !important;
}
.ant-modal-confirm-body .ant-modal-confirm-content {
  font-size: 16px !important;
}

.ant-modal-confirm-body {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.ant-modal-confirm-body > .anticon {
  font-size: 43px !important;
}
