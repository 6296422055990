.SingleDatePicker {
  border-radius: 5.5px;
  border: 1.4px solid #c4c6c5;
  height: 52.2px;
  background-color: inherit;
}

.SingleDatePicker:hover {
  border-color: hsl(0, 0%, 70%);
}

.SingleDatePickerInput {
  width: 100%;
  background-color: inherit;
  border: none;
}

.DateInput {
  background-color: inherit;
  border: none;
}

.DateInput_input {
  height: 23px;
  font-size: 18px;
  color: #3c4240;
  font-weight: 500;
  padding: 16px;
  border: none;
  background-color: inherit;
}

.DateInput_input__focused {
  background: inherit;
  background-color: inherit;
}

@media screen and (max-width: 64em) {
  .SingleDatePicker_picker__portal {
    z-index: 100;
  }
}
