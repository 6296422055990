.modal__container {
    :global {

        .ant-modal-confirm-title,
        .anticon,
        .ant-modal-confirm-content {
            margin: 0 !important
        }

        .ant-modal-confirm-content {
            margin-left: 0 !important;
        }

        .ant-modal-confirm-btns {
            justify-content: flex-end !important;

            button {
                border: none !important;

            }
        }
    }
}