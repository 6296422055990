.select__container {
    background: none;
    width: 100%;
    height: 100%;
    outline: none;
    border: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    text-indent: 1px;
    text-overflow: '';
    padding: 12px;
    color: #00a857;
    font-size: 18px;
    letter-spacing: 0.5px;
    border: 1px solid #d9d9d9;

    &:hover {
        border-bottom: 1px solid #6fd621bd;
    }

    &::-ms-expand {
        display: none;
    }
}