$xsBreakpoint: 576px; // Extra small screen
$smBreakpoint: 768px; // Small screen
$mdBreakpoint: 1024px; // Medium screen
$lgBreakpoint: 1290px; // Large screen
$xlBreakpoint: 1440px; // Extra large screen

//color variables
$backgroundGrey: #FAFAFA;

$buttonBackground: #00a857;

@mixin breakpoint($media) {

    @if $media ==xs {
        @media (max-width: $xsBreakpoint) {
            @content;
        }
    }

    @else if $media ==sm {
        @media (max-width: $smBreakpoint) {
            @content;
        }
    }

    @else if $media ==md {
        @media (max-width: $mdBreakpoint) {
            @content;
        }

    }

    @else if $media ==lg {
        @media (max-width: $lgBreakpoint) {
            @content;
        }

    }

    @else if $media ==xl {
        @media (max-width: $xlBreakpoint) {
            @content;
        }
    }

    @else {
        @media ($media) {
            @content;
        }
    }
}

@mixin ellipse-text {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}