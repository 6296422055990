@import "src/variables.module.scss";

.rental-application-container {
  min-height: calc(70vh - 398px);
  display: flex;
  flex-flow: column nowrap;
  padding: 40px 100px;
  position: relative;

  .btn-group {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 30px;
  }

  .right-side-box {
    p {
      color: #909392;
    }

    .tag-p {
      margin: 30px -40px;
      line-height: 10px;
    }
  }
}

@media screen and (max-width: "500px") and (max-width: "450px") {
  .rental-application-container {
    align-items: flex-start;
    padding: 40px 20px !important;

    .right-side-box {
      width: 100%;

      h2 {
        padding: 0 !important;
      }

      p {
        color: #909392;
      }

      .tag-p {
        margin: 30px -5px;
        line-height: 10px;
      }
    }
  }
}

@media screen and (max-width: "950px") {
  .rental-application-container {
    align-items: flex-start;
    padding: 40px 20px !important;

    .right-side-box {
      width: 100%;

      h2 {
        padding: 0 40px;
      }

      p {
        color: #909392;
      }

      .tag-p {
        margin: 30px -5px;
        line-height: 10px;
      }
    }
  }
}

@media screen and (max-width: "900px") {
  .rental-application-container {
    align-items: flex-start;
    padding: 40px 20px !important;

    .right-side-box {
      width: 100%;

      p {
        color: #909392;
      }

      .tag-p {
        margin: 30px -5px;
        line-height: 10px;
      }
    }
  }
}

@media screen and (max-width: "800px") {
  .rental-application-container {
    align-items: flex-start;
    padding: 20px !important;

    .right-side-box {
      width: 100%;

      p {
        color: #909392;
      }

      .tag-p {
        margin: 30px -5px;
        line-height: 10px;
      }
    }
  }

  .field-container {
    padding: 22px 0;

    // box-shadow: 0 1.2px 6px -2px rgba(0, 0, 0, 0.6);
    & > div:first-child {
      margin-bottom: 18px;
      // padding: 0 20px;
    }

    .switch-group {
      width: calc(100% - 30px) !important;
      display: flex;
      flex-direction: column;
      margin-left: 17px;

      label {
        width: 100%;
      }
    }
  }

  .btn-head {
    display: none !important;
  }

  .btn-head2 {
    display: flex !important;
    flex-direction: row;
    // margin-left: ;
    max-width: 560px;
    align-items: center;
    justify-content: center;
  }

  .rental-application {
    padding: 20px !important;

    .h2 {
      padding: 0 20px;
      font-weight: 600;
    }

    .mediaCss {
      flex-flow: column nowrap;

      label {
        width: calc(100% - 0px);
      }

      & label:first-child {
        margin-bottom: 20px;
      }
    }

    .switch-group {
      flex-flow: column;
    }

    .application-title {
      font-size: 42px;
      line-height: 1;
    }

    .inputField {
      font-size: 18px;
      // margin: 0 20px;
    }

    .pad-x {
      padding: 0 20px;

      .date-field {
        border: 1px solid #d9d9d9;
        min-width: 60vw;
        padding: 10px;
      }
    }

    .radio-btn-multi {
      display: flex;
      flex-flow: column;
      margin-top: 10px;
      margin-left: 10px;

      &:first-child {
        margin-bottom: 0;
      }

      label {
        width: calc(100% - 0px);
      }

      & label:not(:last-child) {
        margin-bottom: 20px;
      }
    }

    .bool-btn {
      justify-content: space-around;

      div {
        width: 40%;
      }
    }
  }
}

.btn-group {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 30px;
}

.rental-application {
  // max-width: 700px;
  padding: 40px 160px;

  u {
    text-decoration-thickness: 2px;
  }

  .auto-complete-input {
    // border-bottom: 1px solid rgba(0, 0, 0, 0.5);
    .Mui-focused {
      border: 2px solid red !important;
    }
  }

  .application-second-title {
    font-size: 35px;
    text-align: center;
  }

  .logo {
    display: flex;
    justify-content: center;
  }

  p {
    font-size: 18px;
    line-height: 25px;
    font-weight: 300;
  }

  .application-title {
    // color: #3c4240;
    color: #70d621;
    text-align: center;
    font-size: 56px;
    margin-top: 20px;
  }

  .full {
    width: 100%;
  }

  .margin-top {
    margin-top: 15px;
  }

  .margin-top-title {
    margin-top: 45px;
  }

  .flex-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .inputField {
    // minWidth: "650px",
    border: 1px solid #d9d9d9;
    width: 100%;
    padding: 12px;
    // border-bottom: 1px solid rgb(0 0 0 / 0.5);
    // padding: 0 3px 3px 3px;
    // color: #70d621;
    color: #00a857;
    font-size: 18px;
    letter-spacing: 0.5px;
    background-color: transparent;
    transition: all 0.1s linear;

    &:hover {
      outline: none;
      border-bottom: 1px solid #6fd621bd;
    }

    &:target,
    &:focus {
      outline: none;
      border-bottom: 1px solid #00a857;
    }

    &:focus-visible {
      outline: none;
      border: 1px solid #d9d9d9 !important;
    }

    &::placeholder {
      color: #00a857;
      font-size: 18px;
      letter-spacing: 0.5px;
      opacity: 0.5;
    }
  }

  .monthlyGrossIncome__input {
    color: #00a857;
    font-size: 18px;
    letter-spacing: 0.5px;
    background-color: transparent;
    transition: all 0.1s linear;

    fieldset {
      border-color: #d9d9d9;
    }

    &:hover {
      fieldset {
        border-color: #d9d9d9;
        border-bottom-color: #6fd621bd !important;
      }
    }

    &:focus {
      fieldset {
        border-color: #d9d9d9 !important;
        border-width: 1px;
      }
    }
  }

  .inputFieldLine {
    width: 100%;
    border: none;
    border-bottom: 1px solid #f4f4f4;
    padding: 0 3px 3px 3px;
    // color: #70d621;
    color: #00a857;
    font-size: 18px;
    letter-spacing: 0.5px;
    background-color: transparent;
    transition: all 0.1s linear;

    &:hover {
      outline: none;
      border-bottom: 1px solid #6fd621bd;
    }

    &:target,
    &:focus {
      outline: none;
      border-bottom: 1px solid #00a857;
    }

    &:focus-visible {
      outline: none;
      border: 1px solid #d9d9d9 !important;
    }
  }

  .pad-wrapper {
    padding: 0px 0px 10px;
  }

  .switch-group {
    display: flex;
    gap: 20px;
    width: 100%;
  }

  .bool-btn {
    display: flex;
    justify-content: space-between;
    flex-flow: row nowrap;
    gap: 20px;

    div {
      width: 100%;
      height: 50px;
      padding: 10px 30px;
      font-size: 18px;
      text-align: center;
      box-shadow: 0 2px 6px -2px rgba(0, 0, 0, 0.5);
      border-radius: 2px;
      cursor: pointer;
    }
  }

  .custom-upload-btn {
    margin-top: 12px !important;

    & > div:first-child {
      span {
        // color: #70d621;
        color: #00a857;
        background-color: white;
        box-shadow: 0 2px 6px -2px rgba(0, 0, 0, 0.5);
        // border-radius: 25px;
        margin-top: 20px;
        padding: 10px;
        cursor: pointer;
        border: 1px solid #d9d9d9;
        width: 100%;
      }
    }

    & > .ant-upload-list.ant-upload-list-picture {
      .ant-upload-list-picture-container {
        button[title="Remove file"] {
          border: none;
          background-color: transparent;
        }

        .ant-upload .ant-upload-select {
          margin-top: 12px;
        }

        .ant-upload-list-item.ant-upload-list-item-error.ant-upload-list-item-list-type-picture {
          border: none;
        }

        .ant-upload-list-item-list-type-picture {
          margin-top: 20px;
          padding: 10px;
          // color: #70d621;
          color: #00a857;
          height: 40px;
          border-radius: 25px;
          border: none;
          box-shadow: 0 2px 6px -2px rgba(0, 0, 0, 0.5);

          // text-align: center;
          .ant-upload-list-item {
            margin-top: 0;
          }
        }
      }
    }
  }

  .textarea-field {
    border: none;
    outline: none;
    resize: none;
    height: 100px;
    // color: #70d621;
    color: #00a857;
    font-size: 18px;
    letter-spacing: 0.5px;
    background-color: rgb(0 0 0 / 0.03);
    padding: 8px;
  }

  .sig-btn-group {
    display: flex;
    justify-content: flex-end;
  }

  .helper-text {
    text-align: center;
    margin-top: 30px;

    span {
      // color: #70d621;
      color: #00a857;
    }
  }

  .radio-btn-multi {
    display: flex;

    &:first-child {
      margin-bottom: 20px;
    }
  }

  .files-name {
    padding: 10px;
    // color: #70d621;
    color: #00a857;
    border-radius: 25px;
    box-shadow: 0 2px 6px -2px rgba(0, 0, 0, 0.5);
    text-align: center;
  }

  .uploaded-files-list {
    margin-bottom: 20px;
  }

  .theme-color {
    color: #00a857;

    &.MuiCheckbox-colorSecondary.Mui-checked {
      color: white;
    }
  }

  @include breakpoint(md) {
    .h2 {
      padding: 0;
    }

    .field-container {
      padding: 22px 0px;

      // box-shadow: 0 2px 6px -2px rgba(0,0,0,0.5);
    }
  }
}

.form {
  // width: 700px;

  p {
    margin: 0;
  }
}

.form-border-box {
  border: 1px solid #d9d9d9;
  padding: 20px 0;
}

.plr {
  padding: 15px;
}

.field-container {
  padding: 22px 0px;
  // box-shadow: 0 1.2px 6px -2px rgba(0, 0, 0, 0.6);
  position: relative;

  p {
    font-weight: 600;
    font-size: 18px;
  }

  .date__container {
    border: 1px solid #d9d9d9;
    padding: 10px;
  }

  & > div:first-child {
    margin-bottom: 18px;
  }

  // box-shadow: 0 2px 6px -2px rgba(0,0,0,0.5);
  a,
  a:visited,
  a:hover,
  a:active {
    color: #70d621;
  }

  .multi-column {
    display: flex;
    flex-flow: row nowrap;

    label {
      width: 50%;
      display: flex;
      flex-flow: column nowrap;
      padding: 0 15px;
    }

    &:not(:last-child) {
      margin-bottom: 15px;
    }
  }
}

.divide {
  margin-top: 10px;
  margin-bottom: 10px;
  border-bottom: 1px solid #f4f4f4;
}

.img {
  height: 20px;
  width: 20px;
  margin-right: 20px;
}

.appDate {
  display: flex;
  flex-direction: row;
}

.appDateLeft {
  color: #8f90a2;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
}

.appDateRight {
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  color: #3c4240;
}

.head {
  padding: 10px;
}

.app-head {
  font-weight: 600;
  font-size: 20px;
  color: #1f2045;
}

.app-box {
  padding: 4px 12px;
  width: 120px;
  height: 26px;
  background: #f4f4f4;
  font-weight: 600;
  font-size: 14px;
  color: #3c4240;
}

.sub-head {
  display: flex;
  flex-direction: row;
}

.left-sub-head {
  font-weight: 400;
  font-size: 15px;
  line-height: 16px;
  color: #8f90a2;
}

.right-sub-head {
  font-weight: 500;
  font-size: 18px;
  line-height: 16px;
  color: #3c4240;
}

.light {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 18px;
  color: #8f90a2;
}

.dark {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 18px;
  color: #3c4240;
}

.row {
  display: flex;
  flex-direction: row;
}

.chip-box-left {
  align-items: center;
  padding: 8px 12px;
  width: 140px;
  height: auto;
  font-size: 16px;
  background: #f4f4f4;
}

.chip-box-right {
  margin-left: 10px;
  align-items: center;
  padding: 8px 12px;
  width: 140px;
  font-size: 16px;
  height: auto;
  background: #f4f4f4;
}

.file {
  padding: 16px;
  gap: 12px;
  width: 274px;
  height: 55px;
  background: #ffffff;
  border: 1px dashed #d9d9d9;
  border-radius: 2px;
  font-weight: 600;
  font-size: 18px;
  line-height: 23px;
  color: #00a857;
}

.link {
  font-weight: 600;
  font-size: 16px;
  line-height: 18px;
  text-decoration-line: underline;
  color: #328dec;
}

.text-box {
  padding: 16px;
  gap: 10px;
  width: auto;
  height: 50px;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
  font-weight: 600;
  font-size: 16px;
  line-height: 18px;
  color: #00a857;
}

.description-box {
  padding: 16px;
  gap: 12px;
  width: 750px;
  height: 79px;
  background: #ffffff;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
}

.cancel-btn {
  padding: 12px 16px;
  gap: 10px;
  width: 73px;
  height: 42px;
  border: 1px solid #909392;
  border-radius: 2px;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  color: #909392;
}

.save-btn {
  padding: 12px 16px;
  gap: 10px;
  width: 61px;
  height: 42px;
  border: 2px solid #00a857;
  border-radius: 2px;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  color: #00a857;
  margin-left: 20px;
}

.row-btn {
  padding: 16px;
  gap: 12px;
  width: auto;
  height: auto;

  background: #ffffff;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
}

.btn-head {
  display: flex;
  flex-direction: row;
  margin-top: 150px;
}

.dot {
  width: 8px;
  height: 8px;
  margin-right: 10px;
  background: #3c4240;
  border-radius: 10px;
}

.tab-label-active {
  display: flex;
  min-height: 20px !important;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  color: rgba(0, 0, 0, 0.85);
  font-weight: 600;
  font-size: 14px;
  z-index: 1;
  text-transform: capitalize;
}

.tab-label-normal {
  min-height: 20px !important;
  color: #909392;
  font-weight: 600;
  font-size: 14px;
  z-index: 1;
  text-align: left;
  text-transform: capitalize;
}

.right-side-box {
  max-width: 560px;
}

.h2 {
  font-weight: 600;
}

.btnicon {
  display: flex !important;
  flex-direction: row !important;
  justify-content: center !important;
}

// .rowss {
//   display: flex;
//   flex-direction: row;
//   justify-content: center;
// }

.btn-head {
  align-items: flex-end;
  justify-content: flex-end;
  margin-top: 50px;
}

.btn-head2 {
  display: none;
  // align-items: flex-end;
  // justify-content: flex-end;
  // margin-top: 50px;
}

.tab-pannel1 {
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 90vh;
  overflow-x: hidden;
  // padding: 40px 0px;
  overflow-y: auto;
  // align-items: center;
  // ::-webkit-scrollbar {
  //   display: block;
  // }
}

@media screen and (max-width: 1075px) {
  .rental-application {
    padding: 40px 100px;

    .right-side-box {
      width: 100%;
    }

    .switch-group {
      width: calc(100% - 0px);
      display: flex;
      flex-direction: column;

      label {
        width: 100%;
      }
    }
  }

  .tab-pannel1 {
    display: flex !important;
    flex-direction: column !important;
    width: 100% !important;
    min-height: 90vh !important;
    overflow-x: hidden !important;
    padding: 0px !important;
    overflow-y: auto !important;
  }

  .btn-head {
    display: flex;
  }

  .btn-head2 {
    display: none;
    flex-direction: row;
    // margin-left: ;
    max-width: 560px;
    align-items: center;
    justify-content: center;
  }

  .form {
    width: auto;

    p {
      margin: 0;
    }
  }
}

.custom-upload-btn {
  & > div:first-child {
    span {
      color: #70d621;
      background-color: white;
      box-shadow: 0 2px 6px -2px rgba(0, 0, 0, 0.5);
      border-radius: 25px;
      margin-top: 20px;
      padding: 10px 50px;
      cursor: pointer;
    }
  }

  & > .ant-upload-list.ant-upload-list-picture {
    .ant-upload-list-picture-container {
      button[title="Remove file"] {
        border: none;
        background-color: transparent;
      }

      .ant-upload-list-item.ant-upload-list-item-error.ant-upload-list-item-list-type-picture {
        border: none;
      }

      .ant-upload-list-item-list-type-picture {
        margin-top: 20px;
        padding: 10px;
        color: #70d621;
        height: 40px;
        border-radius: 25px;
        border: none;
        box-shadow: 0 2px 6px -2px rgba(0, 0, 0, 0.5);

        // text-align: center;
        .ant-upload-list-item {
          margin-top: 0;
        }
      }
    }
  }
}

.custom-upload-btn2 {
  flex-direction: column-reverse;
  display: flex;

  & > div:first-child {
    span {
      color: #00a857;
      background-color: white;
      margin-top: 20px;
      padding: 15px 0 15px 10px;
      cursor: pointer;
      border-radius: 0;
      box-shadow: none;
      border: 1px solid #d9d9d9;
      width: 100%;
      display: block;
      font-weight: 600;
      font-size: 18px;
    }
  }

  & > .ant-upload-list.ant-upload-list-picture {
    .ant-upload-list-picture-container {
      button[title="Remove file"] {
        border: none;
        background-color: transparent;
      }

      .ant-upload-list-item.ant-upload-list-item-error.ant-upload-list-item-list-type-picture {
        border: none;
      }

      .ant-upload-list-item-list-type-picture {
        margin-top: 20px;
        // padding: 10px;
        color: #00a857;
        height: 40px;
        // border-radius: 25px;
        border: none;
        // box-shadow: 0 2px 6px -2px rgba(0, 0, 0, 0.5);

        border-radius: 0;
        box-shadow: none;
        border: 1px solid #d9d9d9;
        padding: 5px 0 5px 10px;
        display: block;
        height: auto;

        // text-align: center;
        .ant-upload-list-item {
          margin-top: 0;
        }
      }
    }
  }
}
.rental-application-fourth-form {
  .MuiGrid-grid-xs-6 {
    flex-grow: 0 !important;
    max-width: 50% !important;
    flex-basis: 50% !important;
  }
}
