#basic-plan {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: flex-start;
  padding: 0 3rem;
  width: 100%;
  margin-top: 3rem;
}

#plan-card {
  margin: 1rem;
  width: 250px;
  background-color: #fff;
  box-shadow: 0 2px 10px 2px rgba(0, 0, 0, 0.19);
  border-radius: 10px;
  margin-bottom: 2.5rem;
  position: relative;
  border: 1px solid #fff;

  &:hover {
    cursor: pointer;
  }
}

.plan-info {
  margin-top: 5px;
  padding: 1rem 0.5rem;
  display: flex;
  flex-direction: column;
}

.plan-info p {
  text-align: center;
  height: 20px;
  font-size: 16px;
}

.select-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 6px;
  border: 1px solid #65ce7b;
  cursor: pointer;
  margin: 1rem 15px 0;
}

.select-btn .btn {
  font-size: 20px;
  padding: 8px 15px;
}

.plan-info h5 {
  text-align: center;
  font-size: 35px;
  font-weight: 600;
}

.plan-list {
  margin: 0px 0;
  height: 190px;
  overflow: auto;
}

.plan-list::-webkit-scrollbar {
  width: 2px;
  border-left: 3px solid var(--third-color);
}

.plan-list::-webkit-scrollbar-thumb {
  background-color: #c4c6c5;
}

.plan-list div {
  line-height: 1;
  border-bottom: 1px solid #00000029;
  padding: 5px 5px;
}

.card-wrapper {
  position: relative;
}

.feature-list {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.feature-list img {
  width: 13px;
  height: 20px;
  object-fit: contain;
  margin-right: 10px;
  margin-top: 5px;
}

.feature-list span {
  font-size: 14px;
  color: #616161;
  width: 90%;
}

.tool-tip {
  width: 10% !important;
  margin-left: 0.5rem;
}

.card-tag {
  margin-top: 2rem;
  width: 105%;
  font-size: 20px;
  padding: 5px 30px;
  color: #fff;
  font-weight: 600;
  border-radius: 12px 0 12px 0;
  margin-left: -11px;
  position: relative;
  height: 100px;
  justify-content: center;
  align-items: center;
  display: flex;
}

.card-triangle {
  z-index: 0;
  position: absolute;
  left: -1px;
  bottom: 5px;
  height: 9px;
  width: 2px;
  transform: rotate(-7deg);
}

.card-triangle img {
  width: 13px;
  object-fit: contain;
}

.plan-recommended {
  position: absolute;
  top: -13px;
  background-color: #fff;
  left: 25%;
  border: 1px solid #514ba8;
  padding: 1px 10px;
  border-radius: 4px;
  color: #908cc9;
  display: flex;
  justify-content: center;
  align-items: center;
}

.plan-recommended img {
  width: 15px;
  margin-right: 6px;
  object-fit: contain;
}

.plan-recommended span {
  font-size: 13px;
}

.pricing-input {
  border: 1px solid #000;
  padding: 4px 15px !important;
  display: flex;
  align-items: center;
  margin-top: 0rem;
  border: 1px solid #ccc;
  border-radius: 6px;
  height: 40px;
  font-size: 16px;
  width: 100%;
  background-color: #fafafa;
}

.MuiDialog-paperFullWidth {
  width: calc(75%) !important;
  min-height: 30% !important;
  max-height: 100% !important;
  /* margin-left: 15% !important; */
}

.MuiDialog-paperFullWidth::-webkit-scrollbar {
  width: 5px;
  margin-bottom: 5px;
  margin-top: 5px;
}

.MuiDialog-paperFullWidth::-webkit-scrollbar-track {
  background-color: #c2c9d2;
}

.ant-notification-notice-description {
  font-size: 16px !important;
}

.MuiDialog-root {
  z-index: 100 !important;
}

.MuiDialog-paperFullWidth::-webkit-scrollbar-thumb {
  border-radius: 8px;
  background: var(--third-color);
}

.buildings-form-title h1 {
  font-size: 32px;
  font-weight: 600;
  line-height: 1;
}

.btn-save-continue {
  margin-right: 1rem;
  border: 1px solid #ccc;
  color: #000 !important;
  padding: 0.3rem 0.8rem;
  border-radius: 6px;
}

.buildings-form-title p,
.buildings-picture-title p,
.upload__image-wrapper p {
  font-size: 17px;
  line-height: 1;
  color: #c2c2c2;
  margin-bottom: 2rem;
}

.btn-lg {
  background-color: var(--third-color) !important;
}

.react-mapbox-ac-input {
  margin-bottom: 0.7rem !important;
}

.buildings-form-dropdown select {
  width: 100%;
  border-radius: 8px;
  height: 50px !important;
  outline-width: 0;
  background-color: transparent;
  font-size: 20px;
  padding: 0 15px;
  border: 1px solid #ccc;
  /* margin-top: 7px !important; */
}

.buildings-form-dropdown select option {
  background-color: #fff;
  color: #000;
}

.buildings-input p {
  font-size: 22px;
  line-height: 0;
  color: #ccc;
}

.buildings-bottom-button {
  display: flex;
  justify-content: space-between;
  margin-top: 2rem;
  align-items: center;
  flex-wrap: wrap;
}

.react-mapbox-ac-menu {
  border-radius: 6px !important;
  margin-top: -0.98rem !important;
}

.react-mapbox-ac-suggestion {
  font-size: 16px !important;
}

.buildings-bottom-button p {
  font-size: 20px;
  color: red;
  cursor: pointer;
  margin-bottom: 0 !important;
}

.buildings-bottom-button button {
  border: none;
  background-color: var(--third-color);
  color: var(--title);
  padding: 0.4rem 2.2rem;
  font-size: 20px;
  border-radius: 6px;
  font-weight: 600;
  cursor: pointer;
}

.buildings-picture-imgae {
  max-width: 100%;
  width: 100%;
  border: 1px dashed #ccc;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1.5rem 0 1rem 0;
  border-radius: 10px;
  cursor: pointer;
  margin-top: 10px;
}

.upload__image-wrapper img {
  width: 40px;
  height: auto;
  margin-bottom: 1rem;
}

.upload__image-wrapper:nth-child(2) {
  line-height: 1;
  text-align: center;
}

.listing-action a .MuiSvgIcon-root {
  font-size: 20px !important;
  margin-left: 5px;
  color: #a09c9c !important;
  cursor: pointer;
  /* margin-top: 1.6rem !important; */
}

.btn-primary {
  background-color: rgb(51, 51, 51) !important;
  text-decoration: none !important;
  padding: 0.5rem 1.9rem !important;
  font-size: 1.25rem !important;
  line-height: 1.5 !important;
  border-radius: 0.3rem !important;
  color: #fff !important;
  box-shadow: none !important;
}

.btn-danger {
  background-color: red !important;
  text-decoration: none !important;
  padding: 0.5rem 1rem !important;
  font-size: 1.25rem !important;
  line-height: 1.5 !important;
  border-radius: 0.3rem !important;
  color: #fff !important;
}

.sweet-alert h2 {
  font-size: 1rem !important;
}

.MuiDataGrid-dataContainer {
  min-height: 156px !important;
  height: 100% !important;
}

.MuiDataGrid-root .MuiDataGrid-viewport {
  min-height: 156px !important;
  max-height: 100% !important;
}

.MuiDataGrid-viewport {
  min-height: 156px !important;
  max-height: 100%;
  height: 100% !important;
}

.MuiDataGrid-renderingZone {
  min-height: 156px !important;
  max-height: 100% !important;
  height: 100% !important;
}

.buildings-image-previews {
  padding: 1rem 0;
  /* overflow-x: scroll; */
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 10px;
}

.buildings-image-preview .buildings-imageIcon {
  width: 70px;
  height: 90px;
  object-fit: cover;
  border-radius: 6px;
  margin-left: 1rem;
  margin-bottom: 1.5rem;
}

.buildings-image-preview .buildings-cancelIcon {
  width: 20px;
  object-fit: cover;
  border-radius: 6px;
  position: absolute !important;
  bottom: 20px;
  left: 76px;
  z-index: 3;
  border-radius: 50%;
  background-color: #fff;
  cursor: pointer;
}

.buildings-image-preview {
  position: relative;
}

.buildings-bottom-naviagtion {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.buildings-bottom-naviagtion p:first-child {
  margin-right: 1.3rem;
  text-align: center;
  color: var(--title2);
}

.input-icon:before {
  left: 150% !important;
}

.css-1vr111p-option {
  font-size: 16px !important;
  display: flex;
  align-items: center;
}

.css-1r4vtzz,
.css-48ayfv {
  border: 1px solid #ccc !important;
  box-shadow: none !important;
  width: 100% !important;
  height: 40px;
  border-radius: 6px !important;
  background-color: #fafafa !important;
  font-size: 20px !important;
  padding: 0 6px;
}

.css-1jllj6i-control {
  display: none !important;
}

.css-11unzgr {
  border: 0.4px solid #ccc;
}

.form-input-error {
  color: red !important;
  font-size: 12px !important;
  line-height: 0;
}

.buildings-form-dropdown .form-input-error,
.buildings-picture-container .form-input-error {
  margin-top: 0.8rem;
}

.buildings-form-dropdowns .error2 {
  margin-top: 1rem;
}

.buildings-inputs input[type="number"]::-webkit-inner-spin-button,
.buildings-inputs input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.footer-btn {
  display: none !important;
}

.MuiDialog-container {
  height: 100%;
  display: flex;
  justify-content: center;
  margin-right: auto;
}

.buttons-right .MuiSvgIcon-root {
  color: #fff !important;
}

.form-control:focus {
  background-color: #fff;
  border-color: #ccc !important;
  outline: 0 !important;
  box-shadow: none !important;
}

.next-btn-blod {
  font-weight: 600;
}

/* media query */
@media (max-width: 900px) {
  .MuiDialog-paperFullWidth {
    margin-left: 0 !important;
    max-height: 75% !important;
  }

  .buildings-bottom-button button {
    margin-bottom: 1rem;
  }

  .buildings-bottom-button p {
    margin-bottom: 15px !important;
  }

  .mapboxgl-ctrl-geocoder {
    max-width: 100% !important;
  }

  .MuiGrid-grid-xs-3 {
    max-width: 100% !important;
    flex-basis: 100% !important;
  }

  .MuiDialog-paper {
    margin: 0 !important;
  }
}

@media screen and (min-width: 1200px) {
  .sweet-alert {
    left: 77px !important;
  }
}

/* .MuiGrid-root.MuiGrid-item.MuiGrid-grid-xs-4 {
  align-self: auto;
} */

@media screen and (max-width: 950px) {
  .MuiGrid-grid-xs-4 {
    flex-grow: 0 !important;
    max-width: 100% !important;
    flex-basis: 100% !important;
  }

  .MuiGrid-grid-xs-6 {
    flex-grow: 0 !important;
    max-width: 100% !important;
    flex-basis: 100% !important;
  }

  .MuiDialog-container {
    margin: 0 1rem !important;
  }
}

@media screen and (max-width: 550px) {
  .upload__image-wrapper p {
    font-size: 18px;
    line-height: 1.3;
  }

  .buildings-bottom-button {
    align-items: start;
    flex-wrap: wrap-reverse;
    align-items: center;
    justify-content: center;
  }

  .buildings-bottom-naviagtion {
    align-items: start;
    margin-top: 1rem;
  }

  .buildings-form-title p,
  .buildings-picture-title p,
  .upload__image-wrapper p {
    line-height: 1.7;
  }

  .MuiDialog-paperFullWidth {
    width: calc(95%) !important;
    height: 75% !important;
    margin: 0 0;
  }

  .MuiFormControl-root
    .MuiTextField-root
    .primary
    .MuiFormControl-marginNormal
    .MuiFormControl-fullWidth {
    max-width: 100% !important;
  }
}

@media screen and (max-width: 700px) {
  #listing-buildings-btn {
    flex-wrap: wrap-reverse;
  }

  #listing-buildings-btn p {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  #listing-buildings-btn .buildings-bottom-naviagtion {
    width: 100%;
    flex-wrap: wrap-reverse;
  }

  #listing-buildings-btn .buildings-bottom-naviagtion button {
    width: 100%;
  }

  .btn-save-continue,
  .buildings-bottom-naviagtion p:first-child {
    margin-right: 0px;
  }
}

#custom-form {
  .sigCanvas {
    border: 1px solid;
    border-radius: 6px;
  }

  .sigImg {
    width: 100%;
    max-width: 6rem;
    aspect-ratio: 16 / 9;
    max-height: 100px;
    border: 1px solid grey;
    padding: 6px;
    border-radius: 6px;
  }
}
