#files {
  margin-top: 10px;
}

#files {
  .files-upload {
    border: 1px dashed #ccc;
    height: 200px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 1rem;
    padding: 1rem 0 1rem;
    border-radius: 4px;
  }
  .files-upload input {
    height: 260px;
    width: 100%;
    opacity: 0;
    cursor: pointer;
    position: absolute;
  }
  .files-type {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .label {
    font-size: 18px;
    line-height: 0;
    color: #737172;
    width: 93%;
    margin-bottom: 0.5rem;
    margin-top: 0.8rem;
    margin-bottom: 1.5rem;
  }

  .files-type span {
    font-weight: 500;
    font-size: 18px;
    color: rgb(153, 148, 148);
    padding: 0 1rem;
  }
  .files-type img {
    width: 40px;
    height: auto;
    margin-bottom: 0.5rem;
  }
}
