#custom-form {
  #reset-year {
    margin-top: 10px;
    float: right;
    color: red;
    cursor: pointer;
    position: absolute;
    top: -11px;
    right: 0px;
    font-size: 15px;
  }

  .label {
    font-size: 18px;
    line-height: 1;
    color: #737172;
    width: 93%;
    // margin-bottom: 0.5rem;
  }

  .picker-panel {
    top: 30px !important;
    left: 0 !important;
    z-index: 99;
  }

  .year-picker {
    padding: 0.2rem 0.1rem !important;
    padding-right: 5px !important;
    width: auto !important;
    height: 40px !important;
    border-radius: 4px !important;
    margin-top: 0px;
    border: 1px solid #ccc !important;
    background-color: #fafafa !important;
  }

  .year-picker:focus {
    background-color: #fff !important;
  }

  .year-input {
    color: rgb(41, 40, 40) !important;
    font-size: 18px !important;
    background-color: #fafafa !important;
  }

  .year-input:focus {
    background-color: #fff !important;
  }

  .buildings-picture-container {
    margin-top: 10px;
  }

  .tenant-select-field {
    margin-top: 8px;
  }

  .labels {
    font-size: 18px;
    line-height: 0;
    color: #737172;
    width: 93%;
    margin-bottom: 0.5rem;
    margin-top: 0.8rem;
  }

  .primary {
    width: 100%;
    // max-height: 7vh;
    border: none;
    border: 1px solid #ccc;
    border-radius: 8px;
    outline-width: 0;
    padding: 0 0.6rem;
    font-size: 22px;

    .MuiInput-root {
      padding: 12px 15px;
    }

    .MuiInput-underline:before {
      border: none;
    }

    .MuiInput-underline:after {
      border: none;
    }

    .MuiInput-underline:hover:not(.Mui-disabled):before {
      border: none !important;
    }

    input::placeholder,
    textarea::placeholder {
      color: #ccc !important;
      opacity: 1 !important;
    }
  }
}

.css-1jllj6i-control {
  display: none !important;
}

.css-1r4vtzz {
  width: 100% !important;
  border: none !important;
  border: 1px solid #ccc !important;
  box-shadow: none !important;
  border-radius: 6px !important;
  padding: 7px !important;
}

#custom-forms {
  margin-bottom: 10px;

  .labels {
    font-size: 19px;
    line-height: 1.3;
    color: #737172;
    width: 93%;
  }

  .primary {
    width: 100%;
    // max-height: 48px !important;
    border: none;
    border: 1px solid #ccc;
    border-radius: 8px;
    outline-width: 0;
    padding: 0 0.6rem;
    font-size: 22px;

    .MuiInput-root {
      padding: 8px 15px;
    }

    .MuiInput-underline:before {
      border: none;
    }

    .MuiInput-underline:after {
      border: none;
    }

    .MuiInput-underline:hover:not(.Mui-disabled):before {
      border: none !important;
    }

    input::placeholder,
    textarea::placeholder {
      color: #ccc !important;
      opacity: 1 !important;
    }
  }
}

.selected-item {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
}

.selected-item-value {
  background-color: #e2e2e2;
  margin-right: 5px;
  padding: 0px 8px;
  border-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 5px;
  color: #737172;
  font-size: 12px;
  margin-top: 8px;
}

.selected-delete {
  margin-left: 10px;
  cursor: pointer;
}

.selected-delete img {
  width: 10px;
  height: auto;
  object-fit: contain;
}

.errorClassName {
  color: red !important;
  font-size: 12px !important;
  position: absolute;
  bottom: -20px;
}

.errorClassName {
  color: red !important;
  font-size: 12px !important;
  position: absolute;
  inset: 0;
  transform: translateY(101%);
}

.error-text {
  color: red !important;
  font-size: 12px !important;
}

#custom-form .primary .MuiInput-root {
  padding: 8px 15px !important;
}

.pricing-input {
  border: 1px solid #000;
  padding: 4px 15px !important;
  display: flex;
  align-items: center;
  margin-top: 0rem;
  border: 1px solid #ccc;
  border-radius: 6px;
  height: 40px;
  font-size: 18px;
  width: 100%;
  background-color: #fafafa;
}

.pricing-input:focus {
  background-color: #fff;
  border: solid 1.4px rgba(51, 51, 51, 0.75);
  outline: none;
}

.pricing-input input {
  width: 100%;
  background-color: #fafafa;
}

.MuiGrid-sp {
  width: calc(100% + 8px);
  margin: -4px;
}

.MuiGrid-cor {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  box-sizing: border-box;
  justify-content: center;
}

.tenant-pricing-input {
  border: 1px solid #000;
  padding: 8px 15px !important;
  display: flex;
  align-items: center;
  // margin-top: 1.3rem;
  border: 1px solid #ccc;
  border-radius: 8px;
  height: 50px;
  font-size: 18px;
  max-width: 90%;
}

.primary-pricing {
  border: none;
  outline-width: 0;
  font-size: 18px;

  &::placeholder {
    font-size: 16px;
    font-family: "Source Sans Pro", sans-serif;
  }
}

.primary-pricing::-webkit-outer-spin-button,
.primary-pricing::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.buildings-image-preview {
  cursor: pointer;
}

@media only screen and (min-width: 0px) and (max-width: 900px) {
  #custom-form {
    .tenant-select-field {
      margin-top: 0px;
    }
  }

  .MuiGrid-grid-xs-4,
  .MuiGrid-grid-xs-6,
  .MuiGrid-grid-xs-8 {
    max-width: 100% !important;
    flex-basis: 100% !important;
  }
}