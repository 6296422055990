#basic-plan {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: flex-start;
  padding: 0 3rem;
  width: 100%;
  margin-top: 3rem;

  #plan-card {
    margin: 1rem;
    width: 250px;
    background-color: #fff;
    box-shadow: 0 2px 10px 2px rgba(0, 0, 0, 0.19);
    border-radius: 10px;
    margin-bottom: 2.5rem;
    position: relative;
    border: 1px solid #fff;

    &:hover {
      cursor: pointer;
    }
  }

  .plan-info {
    margin-top: 5px;
    padding: 1rem 0.5rem;
    display: flex;
    flex-direction: column;
  }

  .plan-info p {
    text-align: center;
    height: 20px;
    font-size: 16px;
  }

  .select-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 6px;
    border: 1px solid #65ce7b;
    cursor: pointer;
    margin: 1rem 15px 0;
  }

  .select-btn .btn {
    font-size: 20px;
    padding: 8px 15px;
  }

  .plan-info h5 {
    text-align: center;
    font-size: 35px;
    font-weight: 600;
  }

  .plan-list {
    margin: 0px 0;
    height: 190px;
    overflow: auto;
  }

  .plan-list::-webkit-scrollbar {
    width: 2px;
    border-left: 3px solid var(--third-color);
  }

  .plan-list::-webkit-scrollbar-thumb {
    background-color: #c4c6c5;
  }

  .plan-list div {
    line-height: 1;
    border-bottom: 1px solid #00000029;
    padding: 5px 5px;
  }

  .card-wrapper {
    position: relative;
  }

  .feature-list {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .feature-list img {
    width: 13px;
    height: 20px;
    object-fit: contain;
    margin-right: 10px;
    margin-top: 5px;
  }

  .feature-list span {
    font-size: 14px;
    color: #616161;
    width: 90%;
  }

  .tool-tip {
    width: 10% !important;
    margin-left: 0.5rem;
  }

  .card-tag {
    margin-top: 2rem;
    width: 105%;
    font-size: 20px;
    padding: 5px 30px;
    color: #fff;
    font-weight: 600;
    border-radius: 12px 0 12px 0;
    margin-left: -11px;
    position: relative;
    height: 100px;
    justify-content: center;
    align-items: center;
    display: flex;

  }

  .card-triangle {
    z-index: 0;
    position: absolute;
    left: -1px;
    bottom: 5px;
    height: 9px;
    width: 2px;
    transform: rotate(-7deg);
  }

  .card-triangle img {
    width: 13px;
    object-fit: contain;
  }

  .plan-recommended {
    position: absolute;
    top: -13px;
    background-color: #fff;
    left: 25%;
    border: 1px solid #514ba8;
    padding: 1px 10px;
    border-radius: 4px;
    color: #908cc9;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .plan-recommended img {
    width: 15px;
    margin-right: 6px;
    object-fit: contain;
  }

  .plan-recommended span {
    font-size: 13px;
  }

  @media screen and (min-width: 900px) and (max-width: 1300px) {
    flex-wrap: wrap;
  }

  @media screen and (min-width: 00px) and (max-width: 900px) {
    padding: 0 10px;
    flex-wrap: wrap;

    #plan-card {
      width: 350px;
      margin: 6px;

    }
  }
}