.buildings {
  display: flex;
  margin: 0 auto;
}

.buildings .buildingContainer {
  margin: 0 2.4rem 0 2.4rem;
  width: 100%;
}

.buildings .modal-container {
  padding: 1rem;
}

.btn-link {
  background-color: var(--third-color);
  color: #fff !important;
  margin-right: 1.5rem;
  text-decoration: none !important;
  padding: 0.5rem 1rem !important;
  font-size: 1.25rem !important;
  line-height: 1.5 !important;
  border-radius: 0.3rem !important;
}

.edit-units-btn {
  margin-left: 6px;
}

.super-app-theme--cell {
  margin-left: 120px !important;
}

.listing-boost {
  cursor: pointer;
}

.buildings .owner-name {
  width: 160px;
  white-space: nowrap;
  font-size: 16px;
  overflow: hidden;
  text-overflow: ellipsis;
  text-transform: capitalize;
}

.buildings .buildingContainer-title h1 {
  font-size: 2rem;
  font-weight: 520;
  margin-top: 2.5rem;
  margin-bottom: 2rem;
}

.building-search-header-right {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
}

.premium-btn {
  padding: 7px 30px;
  border: 1px solid var(--third-color);
  margin-right: 15px;
  border-radius: 6px;
  cursor: pointer;
  font-size: 18px;
  color: #868282;
  font-weight: 600;
  display: flex;
  justify-content: center;
  align-items: center;
}

.buildings .building-search-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  align-content: space-around;
}

.boosted {
  color: #fff !important;
  background-color: var(--third-color);
}

.building-lists {
  margin: 2rem 0;
}

.buildings .building-lists-title {
  font-size: 18px;
  line-height: 0.5;
  margin: 1.5rem 0 0 0;
}

.building-tabs {
  display: flex;
  margin: 2rem 0 0.5rem 0;
  justify-content: space-between;
  align-items: center;
  border-radius: 6px;
  flex-wrap: wrap-reverse;
}

.building-tab {
  display: flex;
  justify-content: space-between;
}

.building-tab button {
  margin-left: 1rem;
  border: none;
  background-color: transparent;
  font-size: 18px;
  font-weight: 500;
  cursor: pointer;
}

.building-tab button:first-child {
  margin-left: 0rem;
}

.buildings .building-lists-table {
  margin: 0;
}

.view-building {
  border: 1px solid var(--third-color);
  padding: 1px 6px;
  border-radius: 4px;
  font-size: 12px !important;
  font-weight: 600;
  color: #817f7f;
}

.buildings .building-lists-table .MuiDataGrid-root .MuiDataGrid-columnHeaderWrapper {
  font-size: 17px !important;
  background-color: #f5f5f5 !important;
}

.buildings .building-lists-table .MuiDataGrid-root .MuiDataGrid-columnHeaderTitle {
  font-weight: 600;
}

.buildings .building-lists-table .MuiDataGrid-root .MuiDataGrid-window {
  font-size: 16px !important;
}

.ant-modal-body {
  padding-top: 0;
}

.buildings .building-lists-table .MuiDataGrid-root .MuiDataGrid-iconSeparator {
  display: none !important;
}

.buildings .building-nextIcon {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 3rem;
}

.listing-action {
  display: flex;
  align-items: center;
  justify-content: center;
}

.listing-action-edit {
  width: 18px !important;
  height: 20px !important;
  margin-right: 6px;
  margin-bottom: 2px;
  cursor: pointer;
}

.listing-action-delete {
  width: 18px !important;
  height: 20px !important;
  margin-right: 6px;
  margin-top: 4px;
  cursor: pointer;
}

.available-false2 {
  cursor: pointer;
  font-size: 17px;
  color: red;
}

.available-true {
  cursor: pointer;
  font-size: 17px;
  color: var(--third-color);
}

.delete-feedback {
  position: relative;
}

.delete-feedback h1 {
  position: absolute;
  top: 0;
  z-index: 2;
}

.modal {
  display: flex !important;
  align-items: center;
  justify-content: center !important;
  border-radius: 10px !important;
}

.disable-listing .ant-modal-title {
  font-size: 25px !important;
  font-weight: 600;
}

.disable-modal {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: thin solid #8d8b8b;
}

.modal-content {
  border-radius: 0.6rem !important;
  width: 100% !important;
}

.disable-modal span {
  font-size: 1.7rem;
  font-weight: 500;
  margin-bottom: 10px;
}

.disable-modal .MuiSvgIcon-root {
  background-color: #fff;
  border: 2px solid #8d8b8b;
  border-radius: 50%;
  font-size: 20px;
  color: #8d8b8b !important;
  cursor: pointer;
  margin-bottom: 4px;
}

.disable-modal-container {
  margin-bottom: 1rem;
  font-size: 20px;
  font-weight: 400;
}

.disable-input {
  width: 100%;
  margin-top: 0.7rem;
}

.disable-input textarea {
  width: 100%;
  height: 12vh;
  outline-width: 0;
  padding: 1.5rem;
  border: thin solid #8d8b8b;
  font-size: 16px;
  border-radius: 6px;
}

.btn-disabling {
  background-color: var(--third-color);
  margin-top: 1rem;
  outline-width: 0;
  align-self: unset;
  padding: 0.5rem 1.5rem;
  border: thin solid transparent;
  border-radius: 6px;
  font-size: 19px;
  cursor: pointer;
  color: #fff;
}

.MuiDataGrid-root .MuiDataGrid-cell--textRight.MuiDataGrid-cell--withRenderer,
.MuiDataGrid-root .MuiDataGrid-cell--textRight.MuiDataGrid-cell--editing {
  justify-content: center !important;
}

.ant-btn-dangerous {
  color: var(--third-color) !important;
  border-color: var(--third-color) !important;
}

.delete-icon {
  width: 18px;
  height: auto;
  margin-right: 6px;
  cursor: pointer;
}

.listing-action .MuiDataGrid-root .MuiDataGrid-columnHeader:focus,
.MuiDataGrid-root .MuiDataGrid-cell:focus {
  outline: none !important;
}

.DayPickerKeyboardShortcuts_show__bottomRight::before {
  display: none !important;
}

.disable-reason-main {
  display: flex;
  flex-direction: column;
}

@media only screen and (min-width: 500px) and (max-width: 1200px) {
  .buildings .building-search-header {
    flex-direction: column;
  }

  .buildings .buildingContainer {
    margin: 0rem;
    width: 100%;
  }
}

@media only screen and (min-width: 0px) and (max-width: 900px) {
  .building-lists {
    margin: 0.5rem 1rem 0 1rem;
  }

  .buildings .buildingContainer-title h1 {
    margin-left: 2rem;
  }

  .buildings .buildingContainer {
    margin: 0 !important;
  }

  .building-search-header-right {
    margin-top: 2rem;
  }
}

@media only screen and (min-width: 0px) and (max-width: 500px) {
  .buildings .buildingContainer-title h1 {
    margin-left: 0.8rem;
  }

  .buildings .building-search-header {
    flex-direction: column;
  }

  .buildings .buildingContainer {
    margin: 0rem !important;
    width: 100%;
  }

  .building-lists {
    margin: 0.5rem 1rem 0 1rem;
  }

  .building-search-header-right {
    flex-direction: column-reverse;
  }

  .premium-btn {
    margin-top: 1rem;
    width: 100%;
    padding: 3px 30px;
    margin-right: 0;
  }
}

@media only screen and (min-width: 900px) and (max-width: 1200px) {
  .buildings .buildingContainer {
    margin: 0rem 2rem !important;
    width: 100%;
  }

  .building-search-header-right {
    margin-top: 2rem;
  }
}

.error {
  border-color: red;
}

.error-message {
  color: red;
  font-size: 12px;
  margin-top: 5px;
}

#multi-tag-container-email {
  #auto-multi-tags {
    width: 100%;
    padding: 8px 15px;
    border-radius: 5.5px;
    border: solid 1.4px rgba(51, 51, 51, 0.2);

    .react-tags__selected-tag {
      margin-right: 10px !important;
      border: 1px solid #d3d3d3 !important;
      border-radius: 4px !important;
      margin-bottom: 5px !important;
    }

    .react-tags__search-input {
      width: 166px !important;
      min-width: 100px !important;
      border: none;
      padding-block: 4px;
      font-size: 17px;
      padding-inline: 4px;
      border-radius: 4px !important;
      padding-left: 10px !important;
    }

    .react-tags {
      display: flex;
      align-items: "center";
    }

    .suggestionPrefix {
      font-size: 20px !important;
    }
  }

  .react-tags {
    position: relative;
    padding: 6px 0 0 6px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.42);

    /* shared font styles */
    font-size: 1em;
    line-height: 1.2;

    /* clicking anywhere will focus the input */
    cursor: text;
  }

  .react-tags.is-focused {
    border-color: #b1b1b1;
  }

  .react-tags__search-input {
    border: none;
    padding-block: 4px;
    font-size: 17px;
    padding-inline: 4px;
    border-radius: 4px !important;
    padding-left: 10px !important;
    width: 100px !important;
  }

  .react-tags__selected {
    display: inline;
  }

  .react-tags__selected-tag {
    display: inline-block;
    box-sizing: border-box;
    margin: 0 6px 6px 0;
    padding: 6px 8px;
    border: 1px solid #d1d1d1;
    border-radius: 2px;
    background: #f1f1f1;

    /* match the font styles */
    font-size: inherit;
    line-height: inherit;
  }

  .react-tags__selected-tag:after {
    content: "\2715";
    color: #aaa;
    margin-left: 8px;
  }

  .react-tags__selected-tag:hover,
  .react-tags__selected-tag:focus {
    border-color: #b1b1b1;
  }

  .react-tags__search {
    display: inline-block;

    /* match tag layout */
    padding: 7px 2px;
    margin-bottom: 6px;

    /* prevent autoresize overflowing the container */
    max-width: 100%;
  }

  .react-tags__selected-tag-name {
    font-size: 18px !important;
  }

  @media screen and (min-width: 30em) {
    .react-tags__search {
      /* this will become the offsetParent for suggestions */
      position: relative;
    }
  }

  .react-tags__search input {
    /* prevent autoresize overflowing the container */
    max-width: 100%;

    /* remove styles and layout from this element */
    margin: 0;
    padding: 0;
    border: 0;
    outline: none;

    /* match the font styles */
    font-size: 17px !important;
    line-height: inherit;
  }

  .react-tags__search input::-ms-clear {
    display: none;
  }

  .react-tags__suggestions {
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    z-index: 100;
    font-size: 17px !important;
  }

  @media screen and (min-width: 30em) {
    .react-tags__suggestions {
      width: 240px;
    }
  }

  .react-tags__suggestions ul {
    margin: 4px -1px;
    padding: 0;
    list-style: none;
    background: white;
    border: 1px solid #d1d1d1;
    border-radius: 2px;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2);
  }

  .react-tags__suggestions li {
    border-bottom: 1px solid #ddd;
    padding: 6px 8px;
  }

  .react-tags__suggestions li mark {
    text-decoration: underline;
    background: none;
    font-weight: 600;
  }

  .react-tags__suggestions li:hover {
    cursor: pointer;
    background: #eee;
  }

  .react-tags__suggestions li.is-active {
    background: #b7cfe0;
  }

  .react-tags__suggestions li.is-disabled {
    opacity: 0.5;
    cursor: auto;
  }
}
