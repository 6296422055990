@import url("https://fonts.googleapis.com/css2?family=Comfortaa:wght@300;400;500;700&display=swap");

body {
  margin: 0;
  padding: 0;
  overflow: hidden;
  background-color: #fafafa;
}

body * {
  font-family: "Source Sans Pro", sans-serif;
}

:root {
  --title: #fff;
  --title2: #000;
  --first-color: #d3d3d3;
  --second-color: rgb(161, 159, 159);
  --third-color: rgb(0, 168, 87);
  --fourth-color: rgb(113, 214, 34);
  --fivth-color: #faf6f7;
  --sixth-color: rgb(238, 236, 236);
  --seventh-color: #2688ad;
  --eighth-color: #86ddd4;
  --nineth-color: #c3bd27;
  --ten-color: #a09e9e;
  --eleven-color: #b1afb0;
  --primary: #f3f3f3;
  --secondary: #5c5a5a;
  --default: #e7e7e7;
  --info: #f5f5f5;
  --danger: red;
}

button,
input,
select,
textarea {
  font-family: "Source Sans Pro", sans-serif;
}

button:focus {
  outline: 0;
}

a {
  color: initial;
  text-decoration: none;
}

main {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow-y: auto;
  box-sizing: border-box;
}

.mapboxgl-popup-content {
  padding: 0 !important;
}

/* @override carousel css */
.alice-carousel {
  width: 100% !important;
  max-width: 1071px;
  margin: 2rem 0 !important;
}

.alice-carousel__prev-btn,
.alice-carousel__next-btn-wrapper {
  display: none !important;
}

.donYUs {
  margin-top: 32px !important;
  margin-left: 5px;
  margin-right: 5px;
  margin-bottom: 10px;
  width: 100% !important;
}

@media (min-width: 1400px) {
  .donYUs {
    margin-top: 23px !important;
  }
}

.rc-slider-tooltip .rc-slider-tooltip-placement-top:first-child {
  display: none !important;
}

.rc-slider-mark-text:nth-child(2) {
  left: 97% !important;
}

.mapboxgl-popup.mapboxgl-popup-anchor-bottom {
  transform: translate(380px, 473px) translate(0px, 0px) translate(-50%, -114%) !important;
}

.ant-popover.ant-popover-placement-bottomLeft {
  overflow: visible;
}

.auth0-lock.auth0-lock .auth0-lock-cred-pane-internal-wrapper {
  height: auto !important;
  overflow: auto;
}

.ant-btn {
  border-radius: 4px;
  -webkit-transition: 250ms linear;
  transition: 250ms linear;
  font-size: 16px;
  font-weight: 600;
  background: #ffffff;
  border: 1px solid #d1d1d1;
  color: #3c4240;
  font-weight: 600;
  display: flex;
  align-items: center;
}

.ant-btn:hover {
  background: #ffffff;
  border: 1px solid #d1d1d1;
  color: #3c4240;
}

.ant-btn-dashed {
  padding: 20px 1.5rem !important;
}

.ant-btn-primary {
  background-color: #00a857 !important;
  border-radius: 6px !important;
  -webkit-transition: 250ms linear;
  transition: 250ms linear;
  font-size: 20px !important;
  font-weight: 600;
  color: white;
  border: none;
  width: auto;
  border-color: #00a857 !important;
  padding: 22px 2rem !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  box-shadow: none !important;
}

.ant-btn-primary[disabled] {
  background-color: #c4c6c5 !important;
  border-color: #c4c6c5 !important;
}

.ant-btn-primary:hover {
  background-color: rgba(0, 168, 87, 0.87);
  color: white;
  border: none;
}

input:matches([type="checkbox"], [type="radio"]):checked {
  background-color: transparent;
  border: none;
}

input:matches([type="checkbox"], [type="radio"]) {
  border: none !important;
  border-color: transparent !important;
}

div.sc-jlyJG:nth-last-child(2) div:nth-last-child(2) span:nth-last-child(2) {
  display: none !important;
}

.DateInput_input.DateInput_input_1 {
  letter-spacing: 0.1px !important;
  font-family: "Source Sans Pro", sans-serif !important;
  opacity: 0.9;
  font-size: 18px !important;
  font-weight: 500;
  color: #333333 !important;
}

.DateInput_input::placeholder {
  letter-spacing: 0.1px !important;
  font-family: "Source Sans Pro", sans-serif !important;
  opacity: 0.35 !important;
  font-size: 18px !important;
  font-weight: 600;
  color: #333333 !important;
}

/* 
@title: Timeline CSS
@description: This CSS will effect the timeline section of services page
@author: HM Nayem
*/

.circle {
  position: relative !important;
}

.circle::after {
  content: "" !important;
  position: absolute !important;
  top: 15px !important;
  left: -11px !important;
  width: 15px !important;
  height: 15px !important;
  background: #2f2f2f !important;
  border-radius: 50% !important;
}

.svg-even {
  position: absolute !important;
  margin-left: 27% !important;
}

.svg-even circle {
  height: 100% !important;
  width: 100% !important;
  fill: none !important;
  stroke-width: 3 !important;
  transform: translate(5px, 2px) !important;
}

.svg-even circle:nth-child(2) {
  stroke: #2f2f2f !important;
  stroke-dasharray: 223px !important;
  stroke-dashoffset: -331px !important;
}

.svg-odd {
  position: absolute !important;
  margin-left: 27% !important;
}

.svg-odd circle {
  height: 100% !important;
  width: 100% !important;
  fill: none !important;
  stroke-width: 3 !important;
  transform: translate(5px, 2px) !important;
}

.svg-odd circle:nth-child(2) {
  stroke: #2f2f2f !important;
  stroke-dasharray: 223px !important;
  stroke-dashoffset: -108px !important;
}

.css-1a9cv00-ValueContainer {
  padding: 0 !important;
}

.display-flex {
  display: flex !important;
  align-items: center !important;
  justify-content: space-between !important;
}

.non-active-faq-item {
  box-shadow: 0px 4px 23px rgba(0, 0, 0, 0.25) !important;
  background: #ffffff !important;
  border-radius: 0.5rem !important;
}

.makeStyles-loaderAlign-2 {
  z-index: 9999999 !important;
}

.active-faq-item {
  background-color: #e4fff2 !important;
  transition: 0.5s !important;
  border-radius: 0.5rem !important;
}

@media (max-width: 1440px) {
  .svg-even {
    margin-left: 26% !important;
  }

  .svg-odd {
    margin-left: 26% !important;
  }
}

@media (max-width: 1024px) {
  .svg-even {
    margin-left: 26% !important;
  }

  .svg-odd {
    margin-left: 26% !important;
  }
}

@media (max-width: 910px) {
  .svg-even {
    margin-left: 25% !important;
  }

  .svg-odd {
    margin-left: 25% !important;
  }

  .svg-even circle:nth-child(2) {
    stroke: #2f2f2f !important;
    stroke-dasharray: 222px !important;
    stroke-dashoffset: -331px !important;
  }
}

@media (max-width: 894px) {
  .svg-even {
    margin-left: 24.5% !important;
  }

  .timeline__middle-icon {
    margin-left: 24% !important;
  }
}

@media (max-width: 860px) {
  .svg-even {
    margin-left: 23% !important;
  }

  .svg-odd {
    margin-left: 24.8% !important;
  }

  .svg-even circle:nth-child(2) {
    stroke-dasharray: 224px !important;
    stroke-dashoffset: -330px !important;
  }
}

@media (max-width: 768px) {
  .circle {
    position: relative !important;
    margin-top: 3rem !important;
  }

  .circle::after {
    content: "" !important;
    position: absolute !important;
    top: -15px !important;
    left: -11px !important;
    background: #2f2f2f !important;
  }

  .timeline {
    margin-top: 6rem !important;
  }

  .svg-even {
    margin-left: 21% !important;
  }

  .svg-odd {
    margin-left: 21% !important;
  }

  .svg-even circle:nth-child(2) {
    stroke-dasharray: 217px !important;
    stroke-dashoffset: -328px !important;
  }

  .down-arrow::after {
    top: -82.88px !important;
    left: -3px !important;
  }

  .down-arrow::before {
    top: -31px !important;
    left: -10.4px !important;
  }
}

.MuiDialog-paperWidthLg {
  max-height: fit-content;
  border-radius: 30px;
}

.MuiPaper-rounded {
  border-radius: 10px !important;
}

.MuiCircularProgress-svg {
  color: rgb(112, 215, 45) !important;
}

a {
  text-decoration: none !important;
}

.bekayY {
  font-weight: 600;
  font-size: 2rem;
  color: #000000;
  margin-top: 0.75rem !important;
}

.jLHxDP {
  font-weight: 600;
  font-size: 2rem;
  color: #000000;
  margin-top: 0.75rem !important;
}

.ant-modal-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.html2canvas-container {
  width: 1800px;
  height: 2300px;
}

.text-overflow-ellipsis {
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.react-tel-input .form-control {
  font-family: "Source Sans Pro", sans-serif;
  font-size: 18px !important;
}

.MuiInputBase-input {
  font-family: "Source Sans Pro", sans-serif !important;
  font-size: 18px !important;
}

.ant-radio-checked .ant-radio-inner {
  border-color: rgba(0, 168, 87, 0.87) !important;
}

.ant-radio-checked .ant-radio-inner:after {
  background-color: rgba(0, 168, 87, 0.87);
}

.ant-radio:hover .ant-radio-inner {
  border-color: rgba(0, 168, 87, 0.87);
}

.info-box {
  padding: 20px;
  border-radius: 10px;
  border: 1px solid #eee;
  margin: 10px;
  white-space: break-spaces;
  font-size: 16px;
  width: 100%;
}
