#drawer-sidebar {
  border-right: 1px solid #d3d3d3;
  min-height: 100%;
  background: #fff;
  position: sticky;
  z-index: 0;

  .drawer-sidebar-main {
    position: sticky;
    top: 140px;
  }

  .slider-item-img {
    cursor: pointer;
    margin-left: 0.5rem;
  }

  .slider-item {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-top: 0.3rem;
    color: #7b7b7b;
    padding: 0.8rem 0.5rem;
    border-left: 5px solid #fff;
  }

  .slider-item-open {
    margin-left: 1rem;
    font-size: 17px;
    color: #7b7b7b;
    display: block;
    font-weight: 600 !important;
  }

  .slider-item img {
    width: 22px;
    object-fit: contain;
    margin-left: 0.7rem;
    height: 22px;
  }

  .slider-item:hover,
  .slider-item:active {
    cursor: pointer;
  }

  .building,
  .listings,
  .dashboards,
  .plans,
  .users,
  .features,
  .articles,
  .pendingListing,
  .requestedListing,
  .unit,
  .profile,
  .message,
  .boostListing,
  .internal-properties,
  .adminInbox,
  .admin-help-center,
  .admin-blogs,
  .rental-application-details,
  .e-mail-configuration,
  .lease-details-table {
    background-color: var(--fivth-color);
    border-left: 5px solid var(--fourth-color);
    color: #000;
  }

  .dashboards h3,
  .building h3,
  .plans h3,
  .users h3,
  .features h3,
  .articles h3,
  .listings h3,
  .requestedListing h3,
  .pendingListing h3,
  .profile h3,
  .message h3,
  .adminInbox h3,
  .boostListing h3,
  .admin-help-center h3,
  .admin-blogs h3,
  .internal-properties h3,
  .rental-application-details h3 {
    color: #000;
    margin-bottom: 0 !important;
  }

  .sidebar {
    position: relative;
    width: 80px;
    z-index: 0;
    height: 100%;
    transition: 0.6s ease-out;
    padding: 0 0px;
    background-color: #fff;
  }

  .sidebar.inactive {
    position: relative;
    width: 200px;
    height: 100%;
    padding: 0 0px;
  }

  .sidebar ul {
    margin: 0;
    padding: 0;
  }

  .sidebar ul[data-open="true"] {
    animation-delay: 0.6s;
    display: block;
  }

  .sidebar ul[data-open="false"] {
    animation-delay: 6s;
    display: none;
  }

  .sidebar-content {
    position: relative;
    width: 100%;
    min-height: 100%;
    margin-top: 3rem;
    bottom: 0;
  }

  .listing-indicator {
    position: relative;
    top: 0;
  }

  .listing-indicator span {
    position: absolute;
    top: -16px;
    color: red;
    font-weight: 700;
    left: 25px;
    height: 20px;
    font-size: 17px;
  }

  @media only screen and (min-width: 0px) and (max-width: 900px) {
    display: none;
  }

  @media only screen and (min-width: 500px) and (max-width: 900px) {
    .slider-item h3 {
      margin-left: 0.4rem;
      font-size: 17px;
    }
  }
}
