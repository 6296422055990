.fileUpload__container {
    font-size: 18px;
    width: fit-content;

    p {
        margin: 0;
    }

    button {
        background: none;
        border: none;
        padding: 10px;
        cursor: pointer;
    }

    .preview__container {
        display: flex;
        flex-direction: column;
        gap: 16px;

        .file {
            padding: 10px;
            border: 1px solid #d9d9d9;
            display: flex;
            align-items: center;
            gap: 20px;

            .pdf__preview {
                cursor: pointer;
                -webkit-transform: rotate(-35deg);
                -moz-transform: rotate(-35deg);
                transform: rotate(-35deg);
                color: red;
            }

            embed,
            img {
                height: 48px;
                width: 48px;
            }

            .fileName {
                margin: 0;
                font-size: 14px;
            }

            .remove__icon {
                color: #3c4240;
                margin-left: auto;
            }
        }
    }

    .button__container {
        margin-top: 12px;
        display: flex;
        align-items: center;
        gap: 20px;
        color: #00a857;
        border: 1px solid #d9d9d9;
    }

    .error {
        color: red;
        margin-top: 12px;
    }
}

.simpleFileUpload__container {
    p {
        margin: 0;
    }

    .button__container {
        width: fit-content;
        margin: auto;
        margin-top: 20px;

        .choose__btn {
            text-transform: none;
            border: 1px solid #464A53;
            border-radius: 2px;
            padding: 12px 20px;
            color: #464A53;
        }

        input:disabled {
            user-select: none;
            pointer-events: none;
        }
    }

    .attachments__container {
        margin-top: 12px;

        .heading {
            font-weight: 600;
            font-size: 18px;
            margin-bottom: 12px;
        }

        .fileList__container,
        .externalURLs__container {
            margin-top: 12px;

            .file__container {
                display: flex;
                align-items: center;
                gap: 20px;
                margin-top: 12px;
                padding: 16px 10px;
                border: 1px solid #d9d9d9;
                width: fit-content;

                embed,
                img {
                    height: 48px;
                    width: 48px;
                }

                &:first-child {
                    margin-top: 0;
                }

                .filename {
                    font-size: 16px;
                }

                .file__preview {
                    cursor: pointer;
                    -webkit-transform: rotate(-35deg);
                    -moz-transform: rotate(-35deg);
                    transform: rotate(-35deg);
                    color: red;
                }

                .delete__icon {
                    cursor: pointer;
                }
            }
        }
    }
}

.max__error {
    color: red;
    margin-top: 12px;
}